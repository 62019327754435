/**
 * Copyright (C) Zorgcampus - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import { useEffect, useRef, useContext, useState } from "react";
import { Message } from "./Message";
import Input from "./Input";
import { ChatContext } from "./ChatContext";
import { IoMdRefreshCircle } from "react-icons/io";
import styles from './Loader.module.css';


export default function ChatRoom({ socketMsg, isOpen, chatId, roomName }) {
  
  const scrollRef = useRef(null);
  
  const { state, dispatch } = useContext(ChatContext);
  const { messages, wsService } = state; 
  const [loading, setLoading] = useState(false)

  const reconnect = (e) => {
    e.preventDefault();
    setLoading(true);
    wsService.registerMessageListener({ reconnectSpinner: setLoading });
    wsService.connect(`${roomName}`);
    dispatch({ type: 'MESSAGES', payload: { text: "Welcome back! How can I help you?" } });
  }

    
  useEffect(() => {
    if (messages.length < 1) {
      const greet = `Welkom op Zorgcampus! Hoe kan ik u helpen?`
      dispatch({  type: 'MESSAGES', payload: {text: greet,} });
    }
  }, []) //eslint-disable-line

  
  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [messages]);
  
  
  return (
    <div className="lg:col-span-2 lg:block">
      <div className="w-full">

        <div className="relative w-full p-3 overflow-y-auto h-[65vh] md:h-[75vh] bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-white">
          {(socketMsg && socketMsg !== "close") ? (
            <div className="absolute top-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90">
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="flex items-center justify-center w-24 h-24">
                  <IoMdRefreshCircle 
                    color="#2E608D" 
                    className={`w-16 h-16 cursor-pointer ${loading ? 'animate-spin' : ''}`} 
                    onClick={reconnect}
                  />
                </div>
              </div>
            </div>
          
          ):(
            <>
              <ul className="space-y-2">
                {messages?.map((message) => (
                  <div key={message.createdAt} ref={scrollRef}>
                    <Message 
                      user={message.user} 
                      text={message.text} 
                      createdAt={message.createdAt} 
                      chatId={chatId}
                    />
                  </div>
                ))}
              </ul>
              {socketMsg === "close" && <div className={styles.close_loader}></div>}
            </>
          )}
        </div>

        <Input socketMsg={socketMsg} isOpen={isOpen} />
      </div>
    </div>
  );
}
