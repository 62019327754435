/**
 * Copyright (C) Zorgcampus - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */


const getTimeAgo = (createdAt) => {
  const currentTime = new Date();
  const createdTime = new Date(createdAt);
  const elapsed = Math.floor((currentTime.getTime() - createdTime.getTime()) / 1000); 

  const timeUnits = [
    { unit: 'dag', threshold: 86400 },
    { unit: 'uur', threshold: 3600 },
    { unit: 'minuut', threshold: 60 },
    { unit: 'seconde', threshold: 5 },
  ];

  for (const unit of timeUnits) {
    if (elapsed >= unit.threshold) {
      const value = Math.floor(elapsed / unit.threshold);
      
      // Als de verstreken tijd meer dan een dag is, retourneer in het formaat "Maand Afkorting Datum, Jaar"
      if (unit.unit === 'dag' && value >= 1) {
        const months = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
        return `${months[createdTime.getMonth()]} ${createdTime.getDate()}, ${createdTime.getFullYear()}`;
      }
      
      return `${value} ${unit.unit}${value > 1 ? 'en' : ''} geleden`;
    }
  }

  return 'Zojuist';
};



class WebSocketService {
  
  constructor() {
    if (WebSocketService.instance) {
      return WebSocketService.instance;
    }
    WebSocketService.instance = this;

    this.ws = null;
    this.roomName = null;
    this.callbacks = {};
    this.reconnectInterval = null;
    this.reconnectAttempts = 0; // Track the number of reconnect attempts
    this.maxReconnectTries = 10; // Maximum number of reconnect attempts
    this.messageQueue = []; // Queue to store messages while disconnected
    this.inactivityTime = 1000 * 60 * 10; // 10 minutes in milliseconds
    this._inactivityTimer = null;
    this.resetInactivityTimer();
    this.socketMsg = null;
    this.toClose = ['Gesloten wegens inactiviteit', 'Geen verdere vragen'];
  }

  // Reset the inactivity timer
  resetInactivityTimer() {
    clearTimeout(this._inactivityTimer);
    clearTimeout(this.closeTimer);
    this._inactivityTimer = setTimeout(() => this.notifyBackend(), this.inactivityTime);
  }


  // Notify backend before disconnecting
  notifyBackend() {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify({ 
        // must be a string that users cannot send by accident even if they try
        message: `__prepare_for_disconnect__`,
        user_type: "customer"
    }));
    }
  };

  closeSequence() {
    this.ifFunction(this.callbacks['setSocketMsg'], "close");
    this.closeTimer = setTimeout(() => {
      this.ifFunction(this.callbacks['setSocketMsg'], this.socketMsg);
      this.ifFunction(this.callbacks['setIsOpen'], false);
      this.disconnect();
    }, 10000);
  };

  // Connect to the WebSocket
  connect(roomName) {
    this.roomName = roomName;
    const local = `wss://automation.zorgcampus.nl/ws/chat/${roomName ?? this.roomName}/`
    // const docker = `wss://0.0.0.0:8000/graphql/ws/chat/${roomName}/`
    this.ws = new WebSocket(local);

    this.ws.onopen = () => {
      // Reset timer on receiving a message
      this.resetInactivityTimer();

      this.ifFunction(this.callbacks['setSocketMsg'], null);
      this.ifFunction(this.callbacks['reconnectSpinner'], false);
      this.reconnectAttempts = 0; // Reset reconnect attempts on successful connection
      this.clearReconnectInterval();
      // Send message queue to sendMessage method
      if(this.messageQueue.length >= 1) this.sendMessage(this.messageQueue);
    };


    this.ws.onmessage = (event) => {
      // Reset timer on receiving a message
      this.resetInactivityTimer();
      
      const { message } = JSON.parse(event.data);
      if (message === '__disconnect_approved__') {
        this.socketMsg = this.toClose[0];
        this.closeSequence();
      }else if (message === '__close_approved__') {
        this.ifFunction(
          this.callbacks['dispatch'], {
            type: 'MESSAGES', 
            payload: { text: "Thanks for visiting!" } 
          }
        );
        this.socketMsg = this.toClose[1];
        this.closeSequence();
      }else if (message && this.callbacks['dispatch']) {
        // This displays the message in the chat
        this.ifFunction(this.callbacks['dispatch'], { type: 'MESSAGES', payload: { text: message } });

        // setLoading(false);
        this.ifFunction(this.callbacks['setLoading'], false);

      }
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    this.ws.onclose = () => {
      // Start reconnect logic only if This.socketMsg is 
      if (!this.toClose.includes(this.socketMsg)) {
        this.setReconnectInterval(); // Reconnect logic
      }
    };
  }

  // Send a message through the WebSocket
  sendMessage(message) {
    // Reset timer on receiving a message
    this.resetInactivityTimer();

    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      // If message is an array, send each message separately
      if (Array.isArray(message)) {
        while (this.messageQueue.length > 0) {
          const message = this.messageQueue.shift();
          this.ws.send(JSON.stringify(message));
        }
        return;
      }
      this.ws.send(JSON.stringify(message));
      
    } else {
      // If the socket is not open, add the message to the queue
      this.messageQueue.push(message);
    }
  }

  // fetch messages from server
  fetchMessages(roomName) {
    this.sendMessage({
      command: "fetch_messages",
      roomName: roomName
    });
  }
    

  // Set an interval to attempt reconnection
  setReconnectInterval() {
    this.clearReconnectInterval();

    // Check if the maximum number of attempts has been reached
    if (this.reconnectAttempts < this.maxReconnectTries) {
      // Calculate the reconnect delay using exponential backoff
      // Here, I've added a variable reconnectAttempts to track the number of 
      // reconnect attempts and a maxReconnectTries to set a limit on 
      // how many times the service will try to reconnect. The delay between 
      // reconnect attempts starts at 1 second and doubles with each failure, 
      // up to a maximum of 30 seconds.
      // This strategy provides a more graceful handling of connection failures
      // and avoids overwhelming the server with connection requests.

      const reconnectDelay = Math.min(1000 * (2 ** this.reconnectAttempts), 30000); // Max delay of 30 seconds


      this.reconnectInterval = setTimeout(() => this.connect(this.roomName), reconnectDelay);
      this.reconnectAttempts += 1;
      this.socketMsg = "Verbinden..."
    } else {
      this.socketMsg = "Verbinding mislukt. Vernieuw om het opnieuw te proberen."      
    }
    this.ifFunction(this.callbacks['setSocketMsg'], this.socketMsg);

  }

  // Clear the reconnect interval
  clearReconnectInterval() {
    if (this.reconnectInterval) {
      clearTimeout(this.reconnectInterval);
      this.reconnectInterval = null;
    }
  }

  // Disconnect and clean up
  disconnect() {
    this.clearReconnectInterval();
    if (this.ws) {
      this.ws.close();
    }
  }

  // Check if external function is a function and call it
  ifFunction(callback, ...args) {
    if (typeof callback === 'function') {
      callback(...args);
    }
  }

  registerMessageListener(callbacksObject) {
    this.callbacks = {...this.callbacks, ...callbacksObject}
  }
};

const wsService = new WebSocketService();


export {
  getTimeAgo,
  wsService,
};
